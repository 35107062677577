import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/Spinner';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import { AuthUserType } from '../redux/actions/authUserActions';
import { DropDownType } from '../redux/actions/dropDownAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import "../styles/Table.css";
import { StudentFeesPayment } from './Interface';
// import imgPath from './images/96a32eec5fcb252d7472769b4f336e5d.jpeg'
type OptionType = { value: string; label: string };

interface StudentPaymentProps {
    id: number | string;
    onclose?: () => void
}

const StudentPayment: React.FC<StudentPaymentProps> = ({ id, onclose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const authUser = useSelector<StoreState, AuthUserType>((state) => state.authUser);
    const dropDownList = useSelector<StoreState, DropDownType[]>((state) => state.dropDown);


    const [paymentType, setPaymentType] = useState("pay");
    const [courseId, setCourseId] = useState<number>(0);
    const [academicYear, setAcademicYear] = useState<string>('');
    const [feesType, setFeesType] = useState<string>('year');
    const [years, setYears] = useState<string>('');
    const [noOfSemester, setNoOfSemester] = useState<string>('');
    const [gridFields, setGridFields] = useState<{ title: string, amount: number, remarks?: string }[]>(Array(15).fill({ title: '', amount: '', remarks: '' }));
    const [loading, setLoading] = useState(false);

    const [amountError, setAmountError] = useState<string>('');
    const [yearsError, setYearsError] = useState<string>('');
    const [academicYearError, setAcademicYearError] = useState<string>('')
    const [noOfSemesterError, setNoOfSemesterError] = useState<string>('');



    const optionsForTitle = dropDownList
        .filter((dl) => dl.category === 'Fees Title')
        .map((dd) => ({ value: dd.title, label: dd.title }));

    const last10Years = Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i);

    const clearHandler = () => {
        setCourseId(0);
        setFeesType('year');
        setYears('');
        setNoOfSemester('');
        setAcademicYear('');
        setLoading(false)
        setGridFields(Array(15).fill({ title: '', amount: '', remarks: '' }));
    };


    const generatePDF = (studentFeesList: StudentFeesPayment[]) => {
        const doc = new jsPDF();
        const imgPath = studentFeesList[0]?.photo;
        const student = studentFeesList.length > 0 ? studentFeesList[0] : null;
        const title = student?.branchName || "Unknown Branch";

        doc.setFontSize(11);
        const pageWidth = doc.internal.pageSize.getWidth();

        const titleX = (pageWidth) / 2;

        doc.text(title, titleX + 5, 12, { align: 'center', maxWidth: 80 });
        doc.addImage(imgPath, 'JPEG', titleX - 70, 3, 30, 30);


        doc.setFontSize(10);
        let yPos = 25;

        const approvedText = student?.approvedBy ? `(${student?.approvedBy})` : "";
        if (approvedText) {
            const textWidth1 = doc.getTextWidth(approvedText);
            const textX1 = (pageWidth - textWidth1) / 2;
            doc.text(approvedText, textX1 + 7, yPos);
            yPos += 5;
        }

        const recognisedText = student?.recognisedBy ? `(${student?.recognisedBy})` : "";
        if (recognisedText) {
            const textWidth2 = doc.getTextWidth(recognisedText);
            const textX2 = (pageWidth - textWidth2) / 2;
            doc.text(recognisedText, textX2 + 7, yPos);
            yPos += 5;
        }

        const sponsoredText = student?.sponsoredBy ? `(${student?.sponsoredBy})` : "";
        if (sponsoredText) {
            const textWidth3 = doc.getTextWidth(sponsoredText);
            const textX3 = (pageWidth - textWidth3) / 2;
            doc.text(sponsoredText, textX3 + 7, yPos);
            yPos += 5;
        }

        const universityText = student?.affiliatedUniversity ? `(${student?.affiliatedUniversity})` : "";
        if (universityText) {
            const textWidth4 = doc.getTextWidth(universityText);
            const textX4 = (pageWidth - textWidth4) / 2;
            doc.text(universityText, textX4 + 7, yPos);
            yPos += 8;
        }

        const placeText = student?.place ? `${student?.place}` : "";
        if (placeText) {
            const textWidth5 = doc.getTextWidth(placeText);
            const textX5 = (pageWidth - textWidth5) / 2;
            doc.text(placeText, textX5 + 7, yPos);
            yPos += 8;
        }

        doc.line(10, yPos - 2, 90, yPos - 2);
        doc.line(120, yPos - 2, 200, yPos - 2);

        doc.setFontSize(14);
        const textWidth6 = doc.getTextWidth("Fees Receipt");
        const textX6 = (pageWidth - textWidth6) / 2;
        doc.text("Fees Receipt", textX6, yPos);
        yPos += 10;

        const headerRows1: any[] = [
            [
                { content: "Bill No: " + (student?.billNo), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Date: " + (LocalFormatDate(student?.date || "default date")), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Name: " + (student?.studentName), colSpan: 4, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Class: " + (student?.courseName), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Regn.No: " + (student?.registerNumber), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
        ];

        autoTable(doc, {
            startY: yPos,
            theme: 'plain',
            styles: { fontSize: 8, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [],
            body: headerRows1,
        });

        const particularsData = gridFields
            .filter(field => field.amount > 0)
            .map((field) => [field.title, `${field.amount}`]);

        const totalAmount = gridFields
            .filter(field => field.amount > 0)
            .reduce((sum, field) => sum + Number(field.amount), 0);

        const footerRows3: any[] = [
            [
                { content: 'Total', styles: { halign: "right" } },
                { content: `Rs. ${totalAmount}`, styles: { halign: "left" } },
            ]
        ];

        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10 },
            head: [['Particulars', 'Rs.']],
            body: particularsData,
            foot: footerRows3,
            headStyles: {
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                cellWidth: 'auto',
                halign: 'center',
                valign: 'middle',
                fontSize: 11,
                fontStyle: 'bold',
                cellPadding: 5,
            },
            bodyStyles: {
                fontSize: 10,
                fontStyle: 'normal',
                cellPadding: 5,
                halign: 'left',
                valign: 'middle',
            },
            footStyles: {
                lineWidth: 0.05,
                lineColor: [0, 0, 0],
                fontSize: 10,
                fontStyle: 'bold',
                cellPadding: 2,
                halign: 'right',
                valign: 'middle',
            },
            didDrawCell: (data) => {
                if (data.row.index >= 0) {
                    if (data.column.index === 0) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                    if (data.column.index === 1) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                    }
                }
            },
        });

        doc.line(10, (doc as any).lastAutoTable.finalY + 12, 200, (doc as any).lastAutoTable.finalY + 12);

        doc.setFontSize(10);
        doc.setFont("times", "italic");
        doc.text("Fees once paid will not be refunded under any circumstances.", 10, (doc as any).lastAutoTable.finalY + 25);
        doc.setFontSize(12);
        doc.setFont("times");
        doc.text("Signature of Receiving Officer", 150, (doc as any).lastAutoTable.finalY + 30);

        doc.save('student_fees_receipt.pdf');
    };

    const addHandler = () => {
        let error = false;

        if (!academicYear) {
            setAcademicYearError('Academic year is required');
            error = true;
        }

        if (feesType === 'year' && !years) {
            setYearsError('Number of Years is required');
            error = true;
        } else if (feesType === 'semester' && !noOfSemester) {
            setNoOfSemesterError('Number of Semesters is required');
            error = true;
        }

        const nonEmptyGridFields = gridFields
            .filter((field) => field.title && field.amount)
            .map((field) => ({
                title: field.title,
                amount: field.amount,
                ...(paymentType === 'concession' && field.remarks ? { remarks: field.remarks } : {})
            }));

        if (nonEmptyGridFields.length === 0) {
            toast.error("At least one Title and Amount must be filled", { position: 'top-right' });
            return;
        }

        const academicPeriod = feesType === 'year' ? years : noOfSemester;
        const endpoint = paymentType === "pay" ? "/studentFee/" : "/studentConcession/";
        const data = { studentId: id, academicYear, feesType, academicPeriod, fees: nonEmptyGridFields, createdBy: authUser.userId };

        setLoading(true);
        if (!error) {
            axios.post(`${apiUrl}${endpoint}`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        if (response.status === 200) {
                            toast.success(response.data.message, { position: 'top-right' });
                            const billNo = response.data.data[0].billNo;

                            if (paymentType === "pay") {
                                axios.get<{ data: StudentFeesPayment[] }>(`${apiUrl}/studentFee/paymentPDF/?id=${id}&billNo=${billNo}`)
                                    .then((response) => {
                                        if (response.status === 200 && response.data.data) {
                                            const studentFeesList: StudentFeesPayment[] = response.data.data;

                                            setTimeout(() => {
                                                if (studentFeesList.length > 0) {
                                                    generatePDF(studentFeesList);
                                                }
                                                clearHandler();
                                                onclose!()
                                            }, 1000);
                                        }
                                    });
                            }
                            clearHandler();
                            onclose!()
                        }
                    }
                })
                .catch((err) => {
                    toast.error("Error occurred. Please try again.", { position: 'top-right' });
                    console.error("Error:", err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleGridChange = (index: number, field: 'title' | 'amount' | 'remarks', value: string) => {
        const updatedGridFields = [...gridFields];
        updatedGridFields[index] = { ...updatedGridFields[index], [field]: value };
        setGridFields(updatedGridFields);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 mb-3">
                    <label style={{ marginRight: "1%" }}>Payment Type:</label>
                    <input
                        type="radio"
                        name="paymentType"
                        value="pay"
                        checked={paymentType === "pay"}
                        onChange={() => setPaymentType("pay")}
                    /> Pay Fees
                    <input
                        type="radio"
                        name="paymentType"
                        value="concession"
                        className="ml-2"
                        checked={paymentType === "concession"}
                        onChange={() => setPaymentType("concession")}
                    /> Concession
                </div>

                <div className='col-sm-6 col-md-4 col-lg-4'>
                    <label className="mt-3 mb-3">Academic Year <span className='text-danger'>*</span>:</label>
                    <select className='form-control' value={academicYear} onChange={(e) => setAcademicYear(e.target.value)}>
                        <option value="">Select Academic Year</option>
                        {last10Years.map((ly) => <option key={ly} value={ly}>{ly}</option>)}
                    </select>
                    {academicYearError && <div className="text-danger">{academicYearError}</div>}
                </div>

                <div className='col-sm-6 col-md-4 col-lg-4'>
                    <label className="mt-3 mb-3">Fees Type <span className="text-danger">*</span>:</label><br />
                    <input
                        type="radio"
                        name="year"
                        value="year"
                        checked={feesType === 'year'}
                        onChange={() => setFeesType('year')}
                    /> Year
                    <input
                        type="radio"
                        name="semester"
                        value="semester"
                        className="ml-2"
                        checked={feesType === 'semester'}
                        onChange={() => setFeesType('semester')}
                    /> Semester
                </div>

                {/* Conditionally render based on Fee Type */}
                {feesType === 'year' ? (
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <label className="mt-3 mb-3"> Years <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control form-select"
                            value={years}
                            onChange={(e) => setYears(e.target.value)}
                        >
                            <option value="">Select Years</option>
                            {[1, 2, 3, 4, 5].map((yearOption) => (
                                <option key={yearOption} value={yearOption}>{yearOption}</option>
                            ))}
                        </select>
                        {yearsError && <div className="text-danger">{yearsError}</div>}
                    </div>
                ) : (
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control form-select"
                            value={noOfSemester}
                            onChange={(e) => setNoOfSemester(e.target.value)}
                        >
                            <option value="">Select Semesters</option>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((semesterOption) => (
                                <option key={semesterOption} value={semesterOption}>{semesterOption}</option>
                            ))}
                        </select>
                        {noOfSemesterError && <div className="text-danger">{noOfSemesterError}</div>}
                    </div>
                )}

                {/* <img src={apiUrl + "/" + studentFeesList[0]?.photoURL }/> */}

                <div className="col-md-12 table-responsive mt-3">
                    <table className="table table-bordered table-striped">
                        <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                            <tr>
                                <th>S.No</th>
                                <th>Title</th>
                                <th>Amount</th>
                                {paymentType === "concession" && <th>Remarks</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {gridFields.map((field, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Select
                                            options={optionsForTitle}
                                            value={optionsForTitle.find((option) => option.value === field.title) || null}
                                            onChange={(selectedOption: SingleValue<OptionType>) =>
                                                handleGridChange(index, 'title', selectedOption?.value || '')
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={field.amount}
                                            onChange={(e) => handleGridChange(index, 'amount', e.target.value)}
                                        />
                                    </td>
                                    {paymentType === "concession" && (
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={field.remarks}
                                                onChange={(e) => handleGridChange(index, 'remarks', e.target.value)}
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="col-md-12 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={addHandler}
                        disabled={loading}
                    >
                        {loading ? <Spinner /> : "Save"}
                    </button>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default StudentPayment;
